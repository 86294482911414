.block-of-elements-cases {
  width: 454px;
  background: #E8E9EE;
  box-shadow: 7px 7px 15px rgba(101, 81, 188, 0.15), -7px -7px 15px #FFFFFF;
  border-radius: 10px;
  padding: 10px 18px 10px 39px;
  box-sizing: border-box;
  margin-top: 17px; }
  .block-of-elements-cases .block-of-elements-cases__title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: #2F2F2F;
    margin-bottom: 7px; }
  .block-of-elements-cases .block-of-elements-cases__elements {
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap; }

.block-of-elements-cases-mob {
  margin-top: 10px;
  width: 100%;
  background: #E8E9EE;
  box-shadow: 7px 7px 15px rgba(101, 81, 188, 0.15), -7px -7px 15px #FFFFFF;
  border-radius: 10px;
  padding: 10px 18px 7px 39px;
  box-sizing: border-box;
  margin-top: 17px; }
  .block-of-elements-cases-mob .block-of-elements-cases__title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: #2F2F2F;
    margin-bottom: 7px; }
  .block-of-elements-cases-mob .block-of-elements-cases__elements {
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap; }
