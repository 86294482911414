.link-button:active {
  box-shadow: rgba(0, 0, 0, 0.15) 5px 5px 15px inset, rgba(255, 255, 255, 0.55) -5px -5px 15px inset; }

.color-style {
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 152%;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }

.margin-title-icon {
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 10px; }

.mobile-link-button {
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  width: 100%;
  height: 58px; }

.link-button {
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  width: 231px;
  height: 61px;
  box-sizing: border-box;
  border-radius: 8px; }
