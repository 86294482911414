.closed {
  margin-bottom: 8px;
  margin-top: 8px;
  width: 489px;
  height: 68px;
  background: #E8E9EE;
  box-shadow: -5px -5px 20px #FFFFFF, 5px 5px 15px rgba(101, 81, 188, 0.15);
  border-radius: 10px;
  text-align: left;
  padding-left: 40px;
  font-family: 'Montserrat', sans-serif; }
  .closed:hover {
    cursor: pointer; }

.mobile-closed {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-top: 4%;
  width: 100%;
  height: 68px;
  box-sizing: border-box;
  background: #E8E9EE;
  box-shadow: -5px -5px 20px #FFFFFF, 5px 5px 15px rgba(101, 81, 188, 0.15);
  border-radius: 10px;
  text-align: left;
  padding-left: 40px;
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  display: flex; }
  .mobile-closed:hover {
    cursor: pointer; }

button,
button:active,
button:focus {
  outline: none; }

button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: #000000;
  background: transparent;
  border: 0px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  height: 68px;
  width: 330px; }

.opened {
  margin-bottom: 8px;
  margin-top: 8px;
  width: 489px;
  background: #E8E9EE;
  border: 0px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: inset -5px -5px 20px #FFFFFF, inset 5px 5px 20px #CCD4E1;
  border-radius: 10px; }
  .opened:hover {
    cursor: pointer; }

.mobile-opened {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-top: 4%;
  background: #E8E9EE;
  border: 0px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: inset -5px -5px 20px #FFFFFF, inset 5px 5px 20px #CCD4E1;
  border-radius: 10px; }
  .mobile-opened:hover {
    cursor: pointer; }

.chemical-element-wrapper-mobile {
  margin-right: 16px;
  margin-bottom: 11px; }

.listChemicalElement {
  padding-left: 50px;
  display: flex;
  margin-bottom: 15px; }

.listChemicalElementMob {
  padding-left: 24px;
  display: flex;
  flex-wrap: wrap; }

.allListChemicalElements {
  padding-bottom: 37px; }

.allListChemicalElementsMob {
  padding-bottom: 37px; }

.text-opened {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #595959;
  padding: 0 45px 45px 65px; }

.opened-title {
  width: 100%;
  text-align: left;
  padding-left: 40px;
  font-family: Montserrat;
  align-items: center;
  display: flex; }
  .opened-title:hover {
    cursor: pointer; }

.opened-title-mob {
  text-align: left;
  padding-left: 40px;
  font-family: Montserrat;
  align-items: center;
  display: flex; }
  .opened-title-mob:hover {
    cursor: pointer; }

body {
  background-color: #E8E9EE; }

.icon-closed {
  margin-right: 15px;
  display: inline-block;
  position: relative;
  bottom: 1px; }
