.page-wrapper-product {
  padding: 115px 55px;
  padding-left: 0;
  padding-right: 0;
  max-width: 1165px;
  margin: 0 auto; }

.PrP-blok-about-margin {
  min-height: 825px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.page-wrapper-product-mobile {
  margin-right: 15px;
  margin-left: 15px; }
