.page-wrapper-enviroment {
  padding: 115px 55px;
  padding-left: 0;
  padding-right: 0;
  max-width: 1165px;
  margin: 0 auto; }

.EP-blok-about-margin {
  min-height: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.page-wrapper-enviroment-mobile {
  margin-left: 15px;
  margin-right: 15px; }
