img.imageCircleSmartmoney, img.imageCircleMobileSmartmoney {
  animation: 10s linear 0s normal none infinite running rot;
  -webkit-animation: 10s linear 0s normal none infinite running rot; }

@keyframes rot {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
