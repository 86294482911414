.nav-bar {
  position: fixed;
  left: 100%;
  height: 100%;
  margin-left: -78px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 78px;
  z-index: 2000;
  text-align: right; }
  .nav-bar .NB-ul {
    margin-bottom: 50vh; }
    .nav-bar .NB-ul .NB-li {
      cursor: pointer;
      list-style-type: none; }
      .nav-bar .NB-ul .NB-li a {
        display: flex;
        flex-direction: row-reverse;
        justify-content: end; }
      .nav-bar .NB-ul .NB-li .NB-link {
        margin-bottom: 25px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        margin-right: 25px;
        color: rgba(101, 81, 188, 0.4);
        width: min-content;
        transition: all .5s; }
      .nav-bar .NB-ul .NB-li .NB-section-number {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        align-items: center; }
        .nav-bar .NB-ul .NB-li .NB-section-number .NB-nubmer {
          margin-right: 10px; }
        .nav-bar .NB-ul .NB-li .NB-section-number .NB-section-line {
          width: 0px;
          height: 2px;
          transition: all .5s; }
    .nav-bar .NB-ul .NB-li > .NB-active .NB-link {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      color: #6551BC; }
    .nav-bar .NB-ul .NB-li > .NB-active .NB-section-number .NB-section-line {
      width: 38px;
      height: 3px;
      background-color: #6551BC; }
