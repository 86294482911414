.massmedia-links-pos {
  display: flex;
  justify-content: space-between;
  margin-top: 102px;
  margin-bottom: 162px; }

.massmedia-links-pos-mobile {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 261px;
  margin-top: 47px; }

.massmedia-link:hover {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .massmedia-link:hover path {
    fill: #6551bc;
    transition-duration: 1s; }
