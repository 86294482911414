.component-benifits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 800px;
  height: 99px; }

.benifit {
  width: 100%; }

.component-benifits-first {
  width: 244px;
  height: 99px;
  background: #e8e9ee;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: inset -7px -7px 20px #ffffff, inset 7px 7px 21px #d1d9e6;
  border-radius: 10px; }

.benifits-first-content {
  display: flex;
  flex-direction: row;
  width: 166px;
  height: 56px;
  margin-top: 21px;
  margin-bottom: 22px;
  margin-left: 37px;
  margin-right: 41px; }

.benifits-first-content-medal {
  display: flex;
  width: 43px;
  height: 43px;
  margin-top: 7px;
  margin-bottom: 6px; }

.benifits-first-content-text {
  display: flex;
  flex-direction: column;
  height: 56px;
  width: 112px;
  margin-left: 11px; }

.benifits-first-content-text-title {
  font-style: normal;
  font-size: 13px;
  line-height: 152%;
  font-weight: bold;
  color: #6551bc; }

.benifits-first-content-text-information {
  font-style: normal;
  font-size: 13px; }

.component-benifits-second {
  width: 244px;
  height: 99px;
  background: #e8e9ee;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: inset -7px -7px 20px #ffffff, inset 7px 7px 21px #d1d9e6;
  border-radius: 10px; }

.benifits-second-content {
  display: flex;
  flex-direction: row;
  width: 213px;
  height: 56px;
  margin-top: 21px;
  margin-bottom: 22px;
  margin-left: 24px;
  margin-right: 7px; }

.benifits-second-content-medal {
  display: flex;
  width: 43px;
  height: 43px;
  margin-top: 7px;
  margin-bottom: 6px; }

.benifits-second-content-text {
  display: flex;
  flex-direction: column;
  height: 56px;
  width: 161px;
  margin-left: 9px; }

.benifits-second-content-text-title {
  font-style: normal;
  font-size: 13px;
  line-height: 152%;
  font-weight: bold;
  color: #6551bc; }

.benifits-second-content-text-information {
  font-style: normal;
  font-size: 13px; }

.component-benifits-third {
  width: 244px;
  height: 99px;
  background: #e8e9ee;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: inset -7px -7px 20px #ffffff, inset 7px 7px 21px #d1d9e6;
  border-radius: 10px; }

.benifits-third-content {
  display: flex;
  flex-direction: row;
  width: 187px;
  height: 56px;
  margin-top: 21px;
  margin-bottom: 22px;
  margin-left: 31px;
  margin-right: 26px; }

.benifits-third-content-medal {
  display: flex;
  width: 43px;
  height: 43px;
  margin-top: 7px;
  margin-bottom: 6px; }

.benifits-third-content-text {
  display: flex;
  flex-direction: column;
  height: 56px;
  width: 132px;
  margin-left: 12px; }

.benifits-third-content-text-title {
  font-style: normal;
  font-size: 13px;
  line-height: 152%;
  font-weight: bold;
  color: #6551bc; }

.benifits-third-content-text-information {
  font-style: normal;
  font-size: 13px; }

.mobile-benifits {
  display: flex;
  justify-content: center; }

.mobile-component-benifits-first {
  width: 244px;
  height: 99px;
  background: #e8e9ee;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: inset -7px -7px 20px #ffffff, inset 7px 7px 21px #d1d9e6;
  border-radius: 10px; }

.mobile-component-benifits-second {
  width: 244px;
  height: 99px;
  background: #e8e9ee;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: inset -7px -7px 20px #ffffff, inset 7px 7px 21px #d1d9e6;
  border-radius: 10px; }

.mobile-component-benifits-third {
  width: 244px;
  height: 99px;
  background: #e8e9ee;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: inset -7px -7px 20px #ffffff, inset 7px 7px 21px #d1d9e6;
  border-radius: 10px; }

.mobile-component-benifits {
  width: 100%; }

.carousel-row {
  display: flex;
  justify-content: center; }

.carousel-item {
  margin: 0px 11.5%; }

.ipad-component-benifits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 752px;
  height: 99px; }
