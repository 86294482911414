.header {
  width: 1165px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px auto; }
  .header .burger {
    margin-top: 17px; }
  .header .menu {
    height: 23px;
    margin-top: 18px;
    display: flex;
    flex-direction: column; }
    .header .menu .line {
      margin-top: 11px;
      height: 0px;
      display: flex;
      flex-direction: row; }
      .header .menu .line .linePart {
        transition-property: all;
        transition-duration: .2s; }
      .header .menu .line .homeFirst {
        width: 0px;
        border: 1px solid #6551BC; }
      .header .menu .line .homeSecond {
        width: 58px;
        border: 1px solid #6551BC; }
      .header .menu .line .homeThird {
        width: 432px;
        border: 1px solid rgba(101, 81, 188, 0.15); }
      .header .menu .line .productFirst {
        width: 98px;
        border: 1px solid rgba(101, 81, 188, 0.15); }
      .header .menu .line .productSecond {
        width: 65px;
        border: 1px solid #6551BC; }
      .header .menu .line .productThird {
        width: 327px;
        border: 1px solid rgba(101, 81, 188, 0.15); }
      .header .menu .line .promouteFirst {
        width: 204px;
        border: 1px solid rgba(101, 81, 188, 0.15); }
      .header .menu .line .promouteSecond {
        width: 112px;
        border: 1px solid #6551BC; }
      .header .menu .line .promouteThird {
        width: 174px;
        border: 1px solid rgba(101, 81, 188, 0.15); }
      .header .menu .line .environmentFirst {
        width: 356px;
        border: 1px solid rgba(101, 81, 188, 0.15); }
      .header .menu .line .environmentSecond {
        width: 47px;
        border: 1px solid #6551BC; }
      .header .menu .line .environmentThird {
        width: 87px;
        border: 1px solid rgba(101, 81, 188, 0.15); }
      .header .menu .line .casesFirst {
        width: 444px;
        border: 1px solid rgba(101, 81, 188, 0.15); }
      .header .menu .line .casesSecond {
        width: 46px;
        border: 1px solid #6551BC; }
      .header .menu .line .casesThird {
        width: 0px;
        border: 1px solid #6551BC; }
    .header .menu .links {
      display: flex;
      justify-content: space-between;
      width: 496px; }
      .header .menu .links .link {
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 152%;
        color: black; }
        .header .menu .links .link .currentLink {
          color: #6551BC; }
  .header .logo {
    width: 188.5px;
    height: 52px; }
  .header .linkToInvestor {
    display: flex;
    cursor: pointer;
    width: 297px;
    height: 23px;
    margin-top: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 152%;
    color: #6551BC;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .header .linkToInvestor .comment {
      width: 24px;
      height: 24px;
      margin-right: 1px; }

.headerMobile {
  position: relative;
  z-index: 100;
  padding: 0px 15px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 13%;
  min-height: 50px;
  background-color: #E8E9EE; }
  .headerMobile .logoMobile {
    width: 270px;
    margin: auto 0px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .headerMobile .burgerMobile {
    margin: auto 0px;
    width: 20px;
    height: 19px;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    .headerMobile .burgerMobile.mobileMenuActive {
      justify-content: center; }
  .headerMobile .upBurgerLine {
    width: 20px; }
    .headerMobile .upBurgerLine.mobileMenuActive {
      transform: translateY(0px) rotate(135deg);
      width: 23px; }
  .headerMobile .midleBurgerLine {
    width: 10px; }
    .headerMobile .midleBurgerLine.mobileMenuActive {
      width: 0px;
      height: 0px;
      border-radius: 0px; }
  .headerMobile .bottonBurgerLine {
    width: 5px; }
    .headerMobile .bottonBurgerLine.mobileMenuActive {
      transform: translateY(-3px) rotate(-135deg);
      width: 23px; }
  .headerMobile.menuMobileActiveHeader {
    position: sticky;
    top: 0; }

.mainBurgerLines {
  height: 3px;
  background: #6551BC;
  border-radius: 200px;
  margin: 0 0 0 auto;
  transition-property: all;
  transition-duration: .2s; }

.menuMobile {
  width: 100%;
  background-color: #6551BC;
  height: 0px;
  z-index: 100;
  position: fixed;
  transition: .4s;
  overflow-x: hidden;
  margin: 0 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .menuMobile a {
    text-decoration: none; }
  .menuMobile .mobileMenuLinks {
    display: flex;
    flex-direction: column;
    justify-content: start; }
    .menuMobile .mobileMenuLinks .mobileMenuLink {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 152%;
      color: #FFFFFF;
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column; }
      .menuMobile .mobileMenuLinks .mobileMenuLink .mobileLinkWrapper {
        display: flex;
        justify-content: start;
        flex-direction: row; }
        .menuMobile .mobileMenuLinks .mobileMenuLink .mobileLinkWrapper .iconWrapper {
          margin-left: 22px;
          margin-right: 15px; }
    .menuMobile .mobileMenuLinks .menuLine {
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
      width: 100%; }
  .menuMobile .investorLink {
    min-height: 90px;
    max-height: 113px;
    background: #E8E9EE;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .menuMobile .investorLink .investorLinkText {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #6551BC;
      width: 200px;
      margin: 0px auto; }

.menuMobileActive {
  height: 87%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
