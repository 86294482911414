.bottom-button {
  margin-top: 58px;
  height: 424px;
  width: 100%;
  background: #6551BC;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  cursor: pointer;
  position: relative; }

.popup-style {
  position: fixed;
  top: calc(50% - 370px);
  left: calc(50% - 572px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0.5); }

.ooo-medcraft {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 15px;
  font-size: 14px;
  font-weight: 500; }

.ooo-medcraft-mob {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 5px;
  font-size: 8px;
  font-weight: 500; }

.mobile-bottom-button {
  margin-top: 58px;
  height: 113px;
  width: 100%;
  background: #6551BC;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  position: relative; }
