.popup-box {
  height: 701px;
  background: #E8E9EE;
  width: 1144px;
  padding-top: 40px;
  z-index: 1000;
  box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0.5);
  position: relative; }

.popup-box-mob {
  padding-left: 15px;
  overflow: scroll;
  padding-right: 15px;
  height: 100%;
  margin-top: 15px;
  background: #E8E9EE;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: calc(100% - 30px); }

.private-policy {
  position: absolute;
  bottom: 3px;
  right: 3px;
  cursor: pointer;
  font-size: 14px;
  color: #2F2F2F; }

.privacy-policy-mob {
  font-size: 12px;
  display: flex;
  color: #2F2F2F;
  margin-top: 5px;
  margin: 15px 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a:-webkit-any-link {
  text-decoration: none; }

.popup-title {
  margin-left: 109px;
  width: 667px;
  height: 37px;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  color: #2F2F2F;
  margin-bottom: 44px;
  margin-top: 44px; }

.input-margin-mob {
  margin-bottom: 40px;
  -moz-appearance: textfield; }

.input-margin-mob::-webkit-inner-spin-button {
  display: none; }

.popup-project-style {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #2F2F2F; }

.popup-description {
  margin-left: 109px;
  margin-bottom: 20px; }

.popup-project-description-mob {
  margin-top: 27px;
  margin-bottom: 20px; }

.popup-project-description {
  width: 400px;
  height: 37px;
  margin-left: 109px; }

.input-description-project {
  width: 467px;
  height: 118px;
  margin-bottom: 27px; }

.input-description-project-mob {
  width: 343px;
  height: 118px; }

.popup-input-description {
  background: #E8E9EE;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: inset 7px 7px 21px #D1D9E6, inset -7px -7px 20px #FFFFFF;
  border-radius: 10px;
  resize: none;
  color: #2F2F2F;
  opacity: 0.5;
  padding: 21px 19px 21px 19px;
  outline: none;
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px; }

.button-not-selected {
  width: 156px;
  height: 53px;
  background: #E8E9EE;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  cursor: pointer; }

.button-not-selected-mob {
  width: 165px;
  height: 53px;
  background: #E8E9EE;
  border: 1px solid #FFFFFF;
  box-sizing: border-box; }

.text-button {
  margin-left: 8px;
  height: 17px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2F2F2F;
  text-align: center;
  margin-top: -1px; }

.icon-button {
  display: flex;
  justify-content: center;
  margin-top: 17px; }

.button-selected {
  width: 156px;
  height: 53px;
  background: #FFCC40;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  cursor: pointer; }

.button-selected-mob {
  width: 165px;
  height: 53px;
  background: #FFCC40;
  border: 1px solid #FFFFFF;
  box-sizing: border-box; }

.box-of-buttons {
  margin-left: 109px;
  margin-bottom: 36px; }

.box-of-buttons-first {
  display: flex; }

.box-of-buttons-second {
  display: flex; }

.button-not-selected:nth-child(2) {
  border-left: 0;
  border-right: 0; }

.button-not-selected-mob:nth-child(2) {
  border-bottom: 0; }

.button-not-selected-mob:nth-child(1) {
  border-bottom: 0; }

.button-selected:nth-child(2) {
  border-left: 0;
  border-right: 0; }

.box-of-buttons-first div {
  border-bottom: 0; }

.box-of-buttons-first-mob div {
  border-right: 0; }

.button-send {
  width: 182px;
  height: 58px;
  background: linear-gradient(84.76deg, #876FF3 -7.64%, #6551BC 145.94%);
  box-shadow: 6px 9px 40px rgba(63, 60, 255, 0.44), -6px -6px 16px rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer; }

.button-not-send {
  width: 182px;
  height: 58px;
  background: linear-gradient(84.76deg, #b2a4f3 -7.64%, #897dbe 145.94%);
  box-shadow: 6px 9px 40px rgba(106, 104, 185, 0.44), -6px -6px 16px rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF; }

.button-send:active {
  box-shadow: rgba(0, 0, 0, 0.15) 5px 5px 15px inset, rgba(255, 255, 255, 0.55) -5px -5px 15px inset, 6px 9px 40px rgba(63, 60, 255, 0.44), -6px -6px 16px rgba(255, 255, 255, 0.6); }

input {
  width: 245px;
  height: 58px;
  background: #E8E9EE;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: inset -7px -7px 20px #FFFFFF, inset 7px 7px 21px #D1D9E6;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #2F2F2F;
  opacity: 0.5; }

input,
input:active,
input:focus {
  outline: none; }

.input-margin {
  margin-right: 40px;
  margin-left: 109px;
  -moz-appearance: textfield; }

.input-margin::-webkit-inner-spin-button {
  display: none; }

.exit-logo {
  margin-right: 40px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer; }

.exit-logo-mob {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 25px; }

.popup-title-mob {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #2F2F2F; }

.popup-description-mob {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #2F2F2F;
  margin-top: 30px;
  margin-bottom: 20px; }

.box-of-buttons-mob {
  display: flex;
  justify-content: center; }

.input-button-mob {
  display: grid; }

.input-email {
  text-align: start;
  padding-left: 20px; }

.text-after-sending {
  font-size: 20px;
  color: green;
  margin-top: 30px;
  justify-content: center;
  display: flex;
  width: calc(100% - 30px); }

.red-text {
  color: red; }

.green-text {
  color: green; }

.button-not-selected:hover {
  background-color: rgba(255, 219, 120, 0.7); }
